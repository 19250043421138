/**
 * ユーザーログ関連の処理です。
 * @class userLog.ts
 * @constructor
 */

import { getFirebaseUserInfo, firestore } from "../firebase";
import { getUnixTime } from "./util";
import { v4 as uuidv4 } from "uuid";
import { postRequest } from "./axiosAction";

// ユーザーログの型
interface USERLOG {
  access_time_unixtime: number;
  application_name: string;
  user_name: string;
  function_name: string;
  content: string;
  device: string;
  update_date: update_date;
}

// Firestoreで保存するupdate_dateの型
interface update_date {
  update_date: any;
  update_user: string;
}

export const userLogging = (
  applicationName: string = "",
  functionName: string = "",
  content: string = ""
): void => {
  if (getFirebaseUserInfo().uid === undefined) {
    console.log("Not logged in");
    return;
  }
  const usersLog: USERLOG = {
    access_time_unixtime: getUnixTime(),
    application_name: applicationName,
    user_name: getFirebaseUserInfo().uid,
    function_name: functionName,
    content,
    device,
    update_date: {
      update_date: firestore.FieldValue.serverTimestamp(),
      update_user: getFirebaseUserInfo().uid,
    },
  };
  const url = process.env.REACT_APP_ACCESSLOG_API!;
  postRequest(url, usersLog, false);
};

const setDeviceType = (): string => {
  const ua = navigator.userAgent;
  const loerUa = navigator.userAgent.toLowerCase();
  if (ua.indexOf("iPhone") > -1) {
    return "iphone";
  } else if (ua.indexOf("Android") > -1 && ua.indexOf("Mobile") > -1) {
    return "android";
  } else if (ua.indexOf("iPad") > -1) {
    return "ipad";
  } else if (/ipad|macintosh/.test(loerUa) && "ontouchend" in document) {
    return "ipad";
  } else if (ua.indexOf("Android") > -1) {
    return "android";
  } else {
    return "pc";
  }
};

// デバイス種別を取得する
const device = setDeviceType();
